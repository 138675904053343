import { Box, HStack, IconButton, Image, Text, VStack } from '@chakra-ui/react'
import { FaPause, FaPlay, FaX } from 'react-icons/fa6'
import './components.css'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import SharedComponents from 'shared-components'
import { Howl, Howler } from 'howler'

const SimpleAudioPlayer = forwardRef(({}, ref) => {
  const [musicData, setMusicData] = useState()
  const [sound, setSound] = useState()
  const [soundId, setSoundId] = useState()

  useEffect(() => {
    const scrollText = document.getElementById('scroll-text')
    const textWidth = scrollText.scrollWidth
    console.log('Text width:', textWidth)
    scrollText.style.setProperty('--text-width', `${textWidth}px`)
    // console.log(scrollWidth, textWidth)
    // if (textWidth <= scrollWidth) {
    //   console.log('Removing anim..')
    //   scrollText.style.animation = null
    // }
  }, [musicData])

  useImperativeHandle(ref, () => ({
    play (songUrl, imageUrl, name) {
      setMusicData({ songUrl, imageUrl, name })
      const sound = new Howl({
        src: songUrl,
        onload: () =>
          setMusicData(prev => ({ ...prev, isLoaded: true, isPlaying: true }))
      })
      const soundId = sound.play()
      setSound(sound)
      setSoundId(soundId)
    }
  }))

  useEffect(() => {
    if (!musicData) sound?.stop()
  }, [musicData])

  function playPause () {
    setMusicData(prev => ({ ...prev, isPlaying: !prev.isPlaying }))
    if (musicData?.isPlaying) sound?.pause()
    else {
      sound?.play(soundId)
    }
  }
  return (
    <HStack w='max-content' display={musicData ? 'flex' : 'none'}>
      <HStack bg='gray.800' px='1' py='1' borderRadius={'full'} spacing={'3'}>
        <SharedComponents.AppComponent.LazyImage
          h='35px'
          w='35px'
          src={musicData?.imageUrl}
          borderRadius={'full'}
          objectFit={'cover'}
        />
        <IconButton
          icon={
            musicData?.isPlaying ? <FaPause size='15' /> : <FaPlay size='15' />
          }
          h='35px'
          w='35px'
          p='0'
          size='sm'
          borderRadius={'full'}
          colorScheme='black'
          _hover={{
            bg: 'white',
            color: 'black'
          }}
          isLoading={!musicData?.isLoaded}
          isDisabled={!musicData?.isLoaded}
          onClick={playPause}
        />
        <Box
          pos='relative'
          w='150px'
          color='white'
          h='20px'
          overflow={'hidden'}
          id='scroll-container'
        >
          <Box id='scroll-text' pos='absolute'>
            <Text
              h='max-content'
              fontSize={'14px'}
              fontWeight={'bold'}
              style={{
                textWrap: 'nowrap'
              }}
            >
              {musicData?.name}
            </Text>
          </Box>
        </Box>
      </HStack>
      <IconButton
        size='md'
        h='44px'
        w='45px'
        icon={<FaX />}
        borderRadius={'full'}
        bg='gray.800'
        color='white'
        _hover={{
          bg: 'red',
          color: 'white'
        }}
        onClick={() => setMusicData(null)}
      />
    </HStack>
  )
})

export const MusicComponent = {
  SimpleAudioPlayer
}
