import {
  Badge,
  Checkbox,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import PageLayout from '../pageLayout'
import UserData from '../../../assets/sampleData/userData.json'
import SharedComponents from 'shared-components'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import * as FirebaseFunctions from 'firebase/functions'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomers } from '../../../redux/slices/customerSlice'

const ColumnDef = [
  {
    label: 'Name',
    key: 'name',
    plaintTextValueEditable: true
  },
  {
    label: 'Email',
    key: 'email'
  },
  {
    label: 'Active Plans',
    key: 'subscriptions',
    renderCell: ({ rowIndex, cellIndex, cellValue }) => {
      const activePlans = []
      cellValue?.data
        ?.filter(sub_single => sub_single.status === 'active')
        ?.map(sub_single => {
          const plans = sub_single.items.data
          plans?.map(plan => {
            activePlans.push({
              id: plan.plan.id,
              amount: plan.plan.amount / 100,
              name: plan.plan.metadata.name,
              interval: plan.plan.interval
            })
          })
        })

      return (
        <Tooltip
          placement='bottom-start'
          hasArrow
          borderRadius={'md'}
          label={
            activePlans.length > 1 ? (
              <VStack align={'flex-start'} spacing={'0'}>
                {activePlans?.map(x => (
                  <Text
                    key={`customer_plan_tooptip_${x.id}`}
                    textTransform={'capitalize'}
                  >
                    {x.name} - ${x.amount}
                    {x.interval ? `/${x.interval}` : ''}
                  </Text>
                ))}
              </VStack>
            ) : (
              ''
            )
          }
        >
          <HStack maxW={'250px'} w='max-content'>
            {activePlans.length > 1 ? (
              <Badge colorScheme='green' textTransform={'capitalize'}>
                {activePlans.length} Plans
              </Badge>
            ) : activePlans.length == 1 ? (
              <Badge colorScheme='green' textTransform={'capitalize'}>
                {activePlans[0].name} - ${activePlans[0].amount}
                {activePlans[0].interval ? `/${activePlans[0].interval}` : ''}
              </Badge>
            ) : (
              <Badge colorScheme='red' textTransform={'capitalize'}>
                Free Tier
              </Badge>
            )}
          </HStack>
        </Tooltip>
      )
    }
  },
  {
    label: 'Joined On',
    key: 'created',
    renderCell: ({ rowIndex, cellIndex, cellValue }) => (
      <Text>
        {moment(cellValue).isValid()
          ? moment.unix(cellValue).format('MMM DD, YYYY')
          : cellValue}
      </Text>
    )
  },
  {
    label: 'Status',
    key: 'status'
  }
]

export default () => {
  // const [tableData, setTableData] = useState(UserData)
  const firebaseContext = SharedComponents.Firebase.context()
  const dispatch = useDispatch()
  const customers = useSelector(state => state.customers)
  const stripeAccountId = useSelector(state => state.user.stripeAccountId)

  // function updateData (rowIndex, cellIndex, cellKey, cellValue) {
  //   const _tableData = [...tableData]
  //   const _rowData = { ..._tableData[rowIndex] }
  //   _rowData[cellKey] = cellValue
  //   _tableData[rowIndex] = _rowData
  //   console.log('Data updated:', _tableData[rowIndex])
  //   setTableData(_tableData)
  // }

  useEffect(() => {
    fetch()
  }, [])

  async function fetch () {
    // setIsBusy(true)
    const _customers = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'listAllCustomers'
    )
    try {
      const result = await _customers({ stripeAccountId })
      // console.log('Data:', result.data)
      dispatch(setCustomers(result.data))
    } catch (ex) {
      console.warn('CloudFunction error:', ex)
    }
    // setIsBusy(false)
  }

  return (
    <PageLayout>
      <SharedComponents.AppComponent.ExtendedTable
        columnDef={ColumnDef}
        data={customers}
      />
    </PageLayout>
  )
}
